import React from 'react';
import { connect } from 'react-redux';

import { Segment, Header, Form, Grid, Message } from 'semantic-ui-react';

import { getConstructionSurvey, postConstructionSurvey, updateConstructionSurvey } from '../../api/constructionAPI';

import { UPDATE_CONSTRUCTION_SURVEY } from '../../js/actionTypes';
import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { SURVEY_TYPES, SURVEY_CATEGORIES } from '../../js/lists';
import { PATH_CONSTRUCTION, PATH_SURVEY, PATH_NEW, PATH_ID, PATH_TIMESTAMP, PATH_ADMIN, PATH_REPORTS, PATH_CRANE, EXTERNAL_PATH_ADM2 } from '../../js/paths';

import { today, formatDateTime } from '../../utils/date';
import ChosenFile from '../../utils/file';

import DateControl from '../../components/DateControl';
import CraneSegment from '../../components/CraneSegment';
import AsyncImage from '../../components/AsyncImage';
import FileChooserSegment from '../../components/FileChooserSegment';
import ConstructionCraneSurveyAckModal from './ConstructionCraneSurveyAckModal';
import ExternalButton from '../../components/ExternalButton';
import SurveyPDF from '../../pdf/surveyPDF';

import EditView from '../EditView';

const INITIAL_STATE = {
  fdid: '',
  fdName: '',
  fdWarning: '',

  project_name: '',
  project_street_number: '',
  project_street_name: '',
  project_city: '',
  project_province: '',
  project_postal_code: '',

  project_l1_name: '',
  project_l1_main_telephone_number: '',
  project_l1_main_telephone_ext: '',
  project_l1_secondary_telephone_number: '',
  project_l1_secondary_telephone_ext: '',

  project_l2_name: '',
  project_l2_main_telephone_number: '',
  project_l2_main_telephone_ext: '',
  project_l2_secondary_telephone_number: '',
  project_l2_secondary_telephone_ext: '',

  afterHoursContactName: '',
  afterHoursContactPhone: '',

  request_type: 'first_survey',
  request_comments: '',
  comments: '',

  availablefrom: today(),
  availableto: '',

  cranes: [''],

  submittedby: '',

  ccAcknowledged: false,

  surveyCategory: Object.keys(SURVEY_CATEGORIES)[0],

  // UI
  isReadOnly: false,

  className: 'construction-survey',
  title: 'Crane Survey Request'
};

// TODO: THIS NEEDS TO SELECT FDS

class ConstructionCraneSurvey extends EditView {
  constructor(props) {
    super(props);

    const urlParams = new URLSearchParams(window.location.search);
    this.viewAsAdmin = urlParams.get('admvw');

    this.state = { ...this.state, ...INITIAL_STATE , files: [] };

    this.state.isNew = (props.match.params[PATH_TIMESTAMP] === PATH_NEW);

    if (this.viewAsAdmin) this.state.exitPage = `/${ PATH_ADMIN }/${ PATH_REPORTS }/${ PATH_CRANE }`;
    else this.state.exitPage = `/${ PATH_CONSTRUCTION }/${ PATH_SURVEY }`;

    if (this.state.isNew) {
      this.state.project_province = this.props.settings.province;
    }

    this.handleChange = this.handleChange.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleCraneNameChange = this.handleCraneNameChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.exit = this.exit.bind(this);
  }

  handleAck(e) {
    this.setState({ccAcknowledged: true}, () => {
      this.onChange();
      this.handleSubmit(e);
    });
  }

  fetch = async () => {
    await getConstructionSurvey(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
    if (this.props.survey.sitesurveyform) {
      this.state.files.length = 0;
      let file = new ChosenFile();
      file.parse(this.props.survey.sitesurveyform);
      if (file.id) {
        this.state.files.push(file);
      }
    }
  }

  onNew = () => {
    this.dispatch({ type: UPDATE_CONSTRUCTION_SURVEY, constructionSurvey: INITIAL_STATE });
  }

  onLoad = () => {
    this.setState({
      ...this.props.survey,
      isNew: (this.props.match.params[PATH_TIMESTAMP] === PATH_NEW),
      isReadOnly: this.props.survey.status !== 'Pending',
      files: (this.props.survey.sitesurveyform ? this.state.files : this.props.survey.files)
    });
  }

  handleCraneNameChange = (event, { index, value }) => {
    let newInfo = [ ...this.state.cranes ];
    newInfo[index] = {...newInfo[index], name: value};

    this.setState({ cranes: newInfo }, () => { this.onChange(); });
  }

  addCrane = (index) => {

    let newInfo = [ ...this.state.cranes ];
    newInfo.splice(index + 1, 0, {name: ""});

    this.setState({ cranes: newInfo }, () => {
      this.onChange();
      document.getElementById(`crane_name_${ index + 1 }`).scrollIntoView();
    });
  };

  removeCrane = (index) => {
    let newInfo = [ ...this.state.cranes ];
    newInfo.splice(index, 1);

    this.setState({ cranes: newInfo }, () => { this.onChange(); });
  };

  isValid = () => {
    let errors = { error: false };

    if (!this.state.project_name) {
      errors.error = true;
      errors.project_name = 'Please enter a project name.';
    }

    // TODO: more validation

    this.setState({ errors: errors });

    return !errors.error;
  }

  create = async (data) => {
    //Check ACK
    if (!this.state.ccAcknowledged) {
      this.ackModal.show();
      return;
    }
    //Make request
    this.setState({ loading: 'Submitting' });
    await postConstructionSurvey(data);
    if (this.props.survey.id) {
      this.exit();
    }
    this.setState({ loading: false });
  }

  update = async (data) => {
    //Chek ACK
    if (!this.state.ccAcknowledged) {
      this.ackModal.show();
      return;
    }
    //Make request
    this.setState({ loading: 'Updating' });
    await updateConstructionSurvey(data);
    if (this.props.survey.id) {
      this.exit();
    }
    this.setState({ loading: false });
  }

  print = event => {
    this.setState({ isPrinting: true });
    const surveyPDF = new SurveyPDF();
    const cranes = this.state.cranes.map(c => c.name).join(' - ');
    surveyPDF.createPDF({ ...this.state, cranes: cranes } );
    this.setState({ isPrinting: false });

    event.preventDefault();
  }

  handleClose(event) {
    if (this.viewAsAdmin) {
      event.preventDefault();

      this.props.history.push(`/${ PATH_ADMIN }/${ PATH_REPORTS }/${PATH_CRANE}`);
      return;
    }

    this.cancel(event);
  }

  handleFDChange = function(event, { name, value }) {
    let selectedFD = this.props.fireDepartments.find(fd => fd.id === value);
    this.setState({ [name]: value, fdWarning: selectedFD.fdWarning }, () => { this.onChange(); });
  }

  componentDidMount() {
    super.componentDidMount();
    //Check for not acknowledged
    setTimeout(()=>{
      if (!this.state.isNew && !this.state.ccAcknowledged && this.ackModal && !this.viewAsAdmin) this.ackModal.show();
    }, 1000);
  }

  renderView = () => {
    const fire_departments = this.props.fireDepartments
      .filter(fd => !fd.isInactive)
      .map(({ id, name }) => {
        return { key: id, value: id, text: name };
      });
    let fdWarning = '';
    if (this.props.fireDepartments.length > 0) {
      const fd = this.props.fireDepartments.find(fd => fd.id === this.state.fdid);
      if (fd && fd.fdWarning) fdWarning= fd.fdWarning;
    }
    const types = Object.keys(SURVEY_TYPES).map((key) => { return { key: key, value: key, text: SURVEY_TYPES[key] }});
    const surveyCategories = Object.keys(SURVEY_CATEGORIES).map((key) => { return { key: key, value: key, text: SURVEY_CATEGORIES[key] }});

    return (
      <Form onSubmit={ this.handleSubmit }>
        <Header size='medium'>
          <Header.Content>{ this.state.title }</Header.Content>
          { !this.state.isReadOnly &&
            <Form.Button floated='right' className='form-top-button' positive>
              { this.state.isNew ? 'Submit' : 'Update' } Request
            </Form.Button>
          }
          <Form.Button floated='right' className='form-top-button' color='blue' type='button' onClick={ this.print }>Print</Form.Button>
          <Form.Button floated='right' className='form-top-button' negative type='button' onClick={this.handleClose}>
            { this.viewAsAdmin ? 'Back' : (this.state.isNew ? 'Cancel' : 'Close') }
          </Form.Button>
          <Header.Subheader>
            Review the Standard Operating Procedure(s) related to this page
            <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_ADM2 } label="ADM2" />
          </Header.Subheader>
        </Header>
        <ConstructionCraneSurveyAckModal ref={(ref)=>{this.ackModal = ref}}
                                         handleSubmit={this.handleAck.bind(this)}/>
        <Segment.Group id="printable" size='large' className='view-segment-group'>
          <Segment disabled={ this.state.isReadOnly }>
            <Form.Group>
              { this.state.isReadOnly &&
                <Form.Input readOnly label='Fire Department' value={ this.state.fdName } name='fdName' width={ 8 }/>
              }
              { !this.state.isReadOnly &&
                <Form.Dropdown search selection required disabled={ !!this.viewAsAdmin }
                  label='Fire Department' name='fdid' value={ this.state.fdid } options={ fire_departments } width={ 8 }
                  onChange={ this.handleFDChange.bind(this) }
                />
              }
              { !this.state.isNew && this.viewAsAdmin &&
                <Form.Field required={ this.state.status === 'Completed' } readOnly={ true }
                  label='Site Survey Form' name='files' width={ 5 }
                  control={ FileChooserSegment } files={ this.state.files } withInput
                />
              }
            </Form.Group>
            { (this.state.fdWarning || fdWarning) &&
              <Message info compact size='tiny' color='yellow' style={ {'whiteSpace': 'pre-wrap'} }>
                { this.state.fdWarning || fdWarning }
              </Message>
            }
            { this.state.visitdate && this.viewAsAdmin &&
              <Form.Field required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='Visit Date' name='visitdate' width={ 4 }
                control={ DateControl } date={ this.state.visitdate } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
              />
            }
            <Form.Group>
              <Form.Input readOnly={ this.state.isReadOnly && !this.state.isPrinting }
                label='Comments' name='comments' value={ this.state.comments } width={ 16 }
                onChange={ this.handleChange }
              />
            </Form.Group>
          </Segment>
          <Segment disabled={ this.state.isReadOnly }>
            <Form.Group>
              <Form.Input required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='Project Name' value={ this.state.project_name } name='project_name' width={ 8 }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='Address Number' value={ this.state.project_street_number } name='project_street_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='Street' value={ this.state.project_street_name } name='project_street_name' width={ 8 }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='City' value={ this.state.project_city } name='project_city' width={ 8 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly
                label='Province' value={ this.state.project_province } name='project_province' width={ 4 }
              />
              <Form.Input required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='Postal Code' value={ this.state.project_postal_code } name='project_postal_code' width={ 4 }
                onChange={ this.handleChange }
              />
            </Form.Group>
          </Segment>
          <Segment disabled={ this.state.isReadOnly }>
            <Form.Group>
              <Form.Input required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='Primary Liason' value={ this.state.project_l1_name } name='project_l1_name' width={ 8 }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='Main Phone' value={ this.state.project_l1_main_telephone_number } name='project_l1_main_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Ext' value={ this.state.project_l1_main_telephone_ext } name='project_l1_main_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Secondary Phone' value={ this.state.project_l1_secondary_telephone_number } name='project_l1_secondary_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Ext' value={ this.state.project_l1_secondary_telephone_ext } name='project_l1_secondary_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
            </Form.Group>
          </Segment>
          <Segment disabled={ this.state.isReadOnly }>
            <Form.Group>
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Secondary Liason' value={ this.state.project_l2_name } name='project_l2_name' width={ 8 }
                onChange={ this.handleChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Main Phone' value={ this.state.project_l2_main_telephone_number } name='project_l2_main_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Ext' value={ this.state.project_l2_main_telephone_ext } name='project_l2_main_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Secondary Phone' value={ this.state.project_l2_secondary_telephone_number } name='project_l2_secondary_telephone_number' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Ext' value={ this.state.project_l2_secondary_telephone_ext } name='project_l2_secondary_telephone_ext' width={ 2 }
                onChange={ this.handleChange }
              />
            </Form.Group>
          </Segment>
          <Segment disabled={ this.state.isReadOnly }>
            <Form.Group>
              <Form.Input required readOnly={ this.state.isReadOnly }
                label='Emergency Contact' value={ this.state.afterHoursContactName } name='afterHoursContactName' width={ 4 }
                onChange={ this.handleChange }
              />
              <Form.Input required readOnly={ this.state.isReadOnly }
                label='Emergency Contact Phone' value={ this.state.afterHoursPhone } name='afterHoursPhone' width={ 4 }
                onChange={ this.handleChange }
              />
            </Form.Group>
          </Segment>
          <Segment disabled={ this.state.isReadOnly }>
            <Form.Group>
              { this.state.isReadOnly &&
                <Form.Input readOnly label='Request Type' name='request_type' value={ SURVEY_TYPES[this.state.request_type] || '' } width={ 4 }/>
              }
              { !this.state.isReadOnly &&
                <Form.Select required
                  label='Request Type' name='request_type' value={ this.state.request_type } options={ types } width={ 4 }
                  onChange={ this.handleChange }
                />
              }
              { this.viewAsAdmin && 
                <Form.Select required
                  label='Category' name='surveyCategory' value={ this.state.surveyCategory } options={ surveyCategories } width={ 4 }
                  onChange={ this.handleChange }
                />
              }
              <Form.Field required={ !this.state.isReadOnly} readOnly={ this.state.isReadOnly }
                label='Anticipated Service End Date' name='availableto' width={ 4 }
                control={ DateControl } date={ this.state.availableto } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                onChange={ this.handleDateChange }
              />
            </Form.Group>
            <Form.Group>
              <Form.Input readOnly={ this.state.isReadOnly }
                label='Comments' value={ this.state.request_comments } name='request_comments' width={ 10 }
                onChange={ this.handleChange }
              />
            </Form.Group>
          </Segment>
          { this.state.cranes.map((crane, index, list) => (
            <CraneSegment index={index} key={index} dataSource={this} disabled={ this.state.isReadOnly }/>
          ))}
          { this.state.signature &&
            <Segment>
              <Header size='small'>
                <Header.Content>Digital signature:</Header.Content>
              </Header>
              <Grid>
                <Grid.Column> <AsyncImage fileName={this.state.signature} size={"medium"}/> </Grid.Column>
              </Grid>
            </Segment>
          }
        </Segment.Group>
      </Form>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    survey: storeState.models.constructionSurvey,
    fireDepartments: storeState.lookups.fireDepartments,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(ConstructionCraneSurvey);
