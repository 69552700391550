import React from 'react';
import { connect } from 'react-redux';

import { Grid, Header, Message, Icon, Table, Button } from 'semantic-ui-react';

import { getFireDepartments, getFireDepartmentSurveys } from '../../api/fireDepartmentAPI';

import { UPDATE_ADMIN_REPORT_CBS_UI } from '../../js/actionTypes';

import { sort, downloadArrayBuffer, xlsxAutoWidth } from '../../utils/utils';

import SortTable from '../../components/SortTable';
import ViewLoader from '../../components/ViewLoader';
import SearchableFilter from '../../components/SearchableFilter';

import ListView from '../ListView';
import { PATH_CONSTRUCTION, PATH_SURVEY } from '../../js/paths';

import * as ExcelJS from 'exceljs/dist/exceljs';

const CC_CATEGORIES = ['All', 'Construction' ,'Maritime'];

class AdminReportCrane extends ListView {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,

      ui : {
        sortField: 'sortDate',
        sortDescending: true,
      },

      ui2: {
        sortField: 'fdName',
        sortDescending: false
      }
    };

    this.updateUIState = this.updateUIState.bind(this);
    this.updateUI2State = this.updateUI2State.bind(this);
    this.fdSurveys = [];
  }

  async componentDidMount() {
    if (this.filterObjYrs !== undefined) this.filterObjYrs.setYears();
    if (this.filterObj !== undefined) this.filterObj.setFilterItems(this.getAvailableStates());
    if (!this.props.fireDepartments || this.props.fireDepartments.length === 0) {
      await getFireDepartments();
    }
    if (this.filterObjFds !== undefined) {
      this.props.fireDepartments.sort((a,b)=>a.name.localeCompare(b.name));
      this.filterObjFds.setFDs(this.props.fireDepartments, true, ()=> {
        this.fetch();
        this.isClean();
      });
    }
    if (this.filterObjCategory !== undefined) {
      this.filterObjCategory.setFilterItems(CC_CATEGORIES);
    }
  }

  fetch = async () => {
    this.setState({ loading: 'Loading' });
    //get filter
    let fdid = this.props.fireDepartments[this.filterObjFds.getSelectedIndex()-1];
    console.log('*-*- fdid started with', fdid);
    if (fdid !== undefined) fdid = fdid.id;
    else fdid = "All";
    console.log('*-*- fdid finished with', fdid);
    const status = (this.filterObj.getSelectedText() === 'All' ? null : this.filterObj.getSelectedText());
    const category = (this.filterObjCategory.getSelectedText() === 'All' ? null : this.filterObjCategory.getSelectedText().toUpperCase());
    const year = this.filterObjYrs.getSelectedText();
    const filters = { status, category, fdid, year };
    await getFireDepartmentSurveys(filters);
    this.buildSurveysByFD(this.props.fireDepartmentSurveys);
    this.setState({ loading: false });
  }

  updateUIState(state) {
    this.fdSurveys.map((fdSurvey) => fdSurvey.surveys = sort(fdSurvey.surveys, this.state.ui.sortField));
    if (!this.state.ui.sortDescending) { this.fdSurveys.map((fdSurvey) => fdSurvey.surveys.reverse())  };
    this.setState({ ui: { ...this.state.ui, ...state } }, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_CBS_UI });
    });
  }

  updateUI2State(state) {
    this.fdSurveys = sort(this.fdSurveys, this.state.ui2.sortField);
    if (!this.state.ui2.sortDescending) { this.fdSurveys.reverse() };
    this.setState({ ui2: { ...this.state.ui2, ...state } }, () =>{
      this.dispatch({ type: UPDATE_ADMIN_REPORT_CBS_UI });
    });
  }

  handleViewSurvey(survey) {
    this.props.history.push(`/${ PATH_CONSTRUCTION }/${ PATH_SURVEY }/${survey.timestamp}/${survey.id}?admvw=true`);
  }

  buildSurveysByFD(surveys) {
    this.fdSurveys = [];
    for (const survey of surveys) {
      const i = this.fdSurveys.findIndex((s) => s.id === survey.id);
      if (i !== -1) {
        this.fdSurveys[i] = { ...this.fdSurveys[i], srNumber: ++this.fdSurveys[i].srNumber };
        this.fdSurveys[i].surveys.push(survey)
      } else {
        console.log('*-*- mapping fd', survey.id)
        const fdName = this.props.fireDepartments.find(fd => fd.id === survey.id).name;
        this.fdSurveys.push({ id: survey.id, fdName: fdName ? fdName : "Invalid FD", srNumber: 1, surveys: [survey] });
      }
    }
    this.fdSurveys = sort(this.fdSurveys, this.state.ui2.sortField);
  }

  handleExportXLSX() {
    this._exportToXLSX();
  }

  //Filter support
  handleFilterChange() {
    this.fetch();
  }
  getAvailableStates() {
    let states = ["All","Pending","Scheduled","Completed","Rejected"];
    return states;
  }

  render() {
    return (
      <div className='fire-department-survey-list'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column' style={{marginLeft: 40, paddingTop: 30}} width={4} floated='left'>
            <Header size='medium'>Reports - Crane Surveys per Department</Header>
          </Grid.Column>
          <Grid.Column className='view-column' style={{marginRight: 40, textAlign:"right", paddingBottom: 0}} width={11} floated='right'>
            <Button primary disabled={!this.props.fireDepartmentSurveys || this.props.fireDepartmentSurveys.length === 0} onClick={this.handleExportXLSX.bind(this)}>Export</Button>
            <SearchableFilter ref={(r)=>this.filterObjFds=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={'All'}/>
            <SearchableFilter ref={(r)=>this.filterObjYrs=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={new Date().getFullYear()}/>
            <SearchableFilter ref={(r)=>this.filterObj=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={'Pending'}/>
            <SearchableFilter ref={(r)=>this.filterObjCategory=r} handleFilterChange={this.handleFilterChange.bind(this)} defaultValue={'All'}/>
          </Grid.Column>
          <Grid.Column width={15}>

            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading }/>;
              }

              if (this.props.fireDepartmentSurveys && !this.props.fireDepartmentSurveys.length) {
                return <Message info size='large'>
                  You don&apos;t have any Surveys.
                </Message>;
              }

              const center = { textAlign: 'center' };

              return <SortTable sortField={ this.state.ui2.sortField } sortDescending={ this.state.ui2.sortDescending } onSort={ this.updateUI2State } headers={[
                { field: 'fdName',      title: 'Fire Department',  width: '2' },
                { field: 'srNumber',    title: 'Requests',         width: '1', style: {...center} },
                { field: 'survey',      title: 'Survey Requests' },
              ]}
              footers={[
                [ `Total Requests: ${this.props.fireDepartmentSurveys.length}`, null, null ]
              ]}>
                  { this.fdSurveys.map((fdSurvey) => (
                    <SortTable.Row key={fdSurvey.id}>
                      <SortTable.Cell>{ fdSurvey.fdName }</SortTable.Cell>
                      <SortTable.Cell style={ center }>{ fdSurvey.srNumber }</SortTable.Cell>
                      <SortTable.Cell>
                        <SortTable sortField={ this.state.ui.sortField } sortDescending={ this.state.ui.sortDescending } onSort={ this.updateUIState } headers={[
                          { field: 'sortDate',              title: 'Submission Date', width: '3' },
                          { field: 'sortVisitDate',         title: 'Visit Date',      width: '3' },
                          { field: 'ccName',                title: 'Company Name',    width: '3', style: {...center} },
                          { field: 'status',                title: 'Status',          width: '2', style: {...center} },
                          { field: 'amount',                title: 'Crane Location',  width: '10', style: {...center} },
                          { field: 'buttons', noSort: true, title: '',                width: '2'               },
                        ]}>
                          { fdSurvey.surveys.map((survey) => (
                            <SortTable.Row key={ survey.key }>
                              <SortTable.Cell>{ survey.displayDate }</SortTable.Cell>
                              <SortTable.Cell>{ survey.displayVisitDate }</SortTable.Cell>
                              <SortTable.Cell {...center}>{ survey.ccName }</SortTable.Cell>
                              <SortTable.Cell {...center}>{ survey.status }</SortTable.Cell>
                              <SortTable.Cell {...center}>{survey.project_name} - {survey.project_street_number} {survey.project_street_name}, {survey.project_city}, {survey.project_province}, {survey.project_postal_code}</SortTable.Cell>
                              <SortTable.Cell textAlign='right'><Icon name='eye' size='large' color='blue' onClick={this.handleViewSurvey.bind(this, survey)} style={{ cursor: 'pointer' }} /></SortTable.Cell>
                            </SortTable.Row>
                          ))}
                        </SortTable>
                      </SortTable.Cell>
                    </SortTable.Row>
                  ))}
              </SortTable>;
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }

  /* Private XLSX */
  async _exportToXLSX() {
    if (!this.fdSurveys || this.fdSurveys.length <= 0) return;
    // Starts XLSX
    const wb = new ExcelJS.Workbook();
    const ws = wb.addWorksheet('Sheet1');
    // Generate XLSX header
    ws.addRow(['Fire Department', 'Submission Date', 'Company Name', 'Status', 'Crane Location']);
    // Generate XLSX rows
    for (const fdSurvey of this.fdSurveys) {
      for (const survey of fdSurvey.surveys) {
        const location = `${survey.project_name} - ${survey.project_street_number} ${survey.project_street_name}, ${survey.project_city}, ${survey.project_province}, ${survey.project_postal_code}`;
        ws.addRow([
          fdSurvey.fdName, survey.displayDate, survey.ccName, survey.status, location
        ]);
      }
    }
    // Auto width
    xlsxAutoWidth(ws, 75);
    // Generate buffer and save it
    const buffer = await wb.xlsx.writeBuffer();
    downloadArrayBuffer(buffer, `site-surveys-report`, 'xlsx');
  }
}

function mapStoreStateToProps(storeState) {
  return {
    fireDepartmentSurveys: storeState.models.fireDepartmentSurveys,
    fireDepartments: storeState.lookups.fireDepartments,
  };
}

export default connect(mapStoreStateToProps)(AdminReportCrane);
