import React from 'react';
import { connect } from 'react-redux';

import { Grid, Segment, Header, Form, Message, Image, Dimmer, Loader } from 'semantic-ui-react';

import logo from '../../images/bccsa_logo.png';

import { getSettings } from '../../api/administratorAPI';
import { getFireDepartmentSurvey, updateFireDepartmentSurvey, postFireDepartmentSurvey } from '../../api/fireDepartmentAPI';
import { getUser } from '../../api/userAPI';

import { DATE_FULL_MONTH_DAY_YEAR } from '../../js/constants';
import { SURVEY_TYPES, SURVEY_STATUS_LIST, SURVEY_CATEGORIES } from '../../js/lists';
import { PATH_FIRE_DEPARTMENT, PATH_SITE_SURVEY, PATH_NEW, PATH_ID, PATH_TIMESTAMP, EXTERNAL_PATH_ADM2 } from '../../js/paths';

import ChosenFile from '../../utils/file';
import { today, formatDateTime } from '../../utils/date';

import DateControl from '../../components/DateControl';
import FileChooserSegment from '../../components/FileChooserSegment';
import ViewLoader from '../../components/ViewLoader';
import CraneSegment from '../../components/CraneSegment';
import AsyncImage from '../../components/AsyncImage';
import ConstructionCraneSurveyAckModal from './../Construction/ConstructionCraneSurveyAckModal';
import ExternalButton from '../../components/ExternalButton';
import SurveyPDF from '../../pdf/surveyPDF';

import EditView from '../EditView';

const INITIAL_STATE = {
  fdid: '',
  fdName: '',

  project_name: '',
  project_street_number: '',
  project_street_name: '',
  project_city: '',
  project_province: '',
  project_postal_code: '',

  project_l1_name: '',
  project_l1_main_telephone_number: '',
  project_l1_main_telephone_ext: '',
  project_l1_secondary_telephone_number: '',
  project_l1_secondary_telephone_ext: '',

  project_l2_name: '',
  project_l2_main_telephone_number: '',
  project_l2_main_telephone_ext: '',
  project_l2_secondary_telephone_number: '',
  project_l2_secondary_telephone_ext: '',

  afterHoursContactName: '',
  afterHoursPhone: '',

  request_type: 'first_survey',
  request_comments: '',

  availablefrom: today(),
  availableto: '',

  cranes: [''],

  submittedby: '',

  ccAcknowledged: false,

  // CC fields
  ccName: '',
  ccContactName: '',
  ccContactPhone: '',
  ccContactEmail: '',

  // FD fields

  visitdate: null,
  comments: '',
  status: '',
  surveyCategory: Object.keys(SURVEY_CATEGORIES)[0],

  sitesurveyform: '',

  // UI fields

  loading: false,
  isNew: false,
  isPrinting: false,
  isReadOnly: false,
  errors: {
    error: false
  },

  files: [],
  progressPercent: 0,
  progressText: '',
  ackOpen: false
};

class FireDepartmentSiteSurvey extends EditView {
  constructor(props) {
    super(props);
    this.handleCraneNameChange = this.handleCraneNameChange.bind(this);

    this.state = { ...INITIAL_STATE };

    this.state.isNew = (props.match.params[PATH_TIMESTAMP] === PATH_NEW);
    this.state.files = [];
  }

  componentDidMount = () => {
    this.initialize();
    this.isClean();
  }

  initialize = async () => {
    this.setState({ loading: 'Loading' });

    if (!this.state.isNew) {
      await getFireDepartmentSurvey(this.props.match.params[PATH_ID], this.props.match.params[PATH_TIMESTAMP]);
      this.setState(this.props.survey);

      await getUser(this.state.ccId);

      let ccName, ccContactName, ccContactPhone, ccContactEmail;
      if (this.props.cc.id) {
        ccName = this.props.cc.entity_name;
        ccContactName = this.props.cc.contact_name;
        ccContactPhone = this.props.cc.main_telephone_number;
        ccContactEmail = this.props.cc.contact_email;
      }

      this.setState({
        ccName: ccName || '',
        ccContactName: ccContactName || '',
        ccContactPhone: ccContactPhone || '',
        ccContactEmail: ccContactEmail || '',
        isReadOnly: this.state.status === 'Completed',
      });

      if (this.state.sitesurveyform) {
        this.state.files.length = 0;
        let file = new ChosenFile();
        file.parse(this.state.sitesurveyform);
        if (file.id) {
          this.state.files.push(file);
        }
      }
    } else {
      await getSettings();
      await getUser(this.props.settings.defaultcc);
      let ccName, ccContactName, ccContactPhone, ccContactEmail;
      if (this.props.cc.id) {
        ccName = this.props.cc.entity_name;
        ccContactName = this.props.cc.contact_name;
        ccContactPhone = this.props.cc.main_telephone_number;
        ccContactEmail = this.props.cc.contact_email;
      }

      this.setState({
        ccName: ccName || '',
        ccContactName: ccContactName || '',
        ccContactPhone: ccContactPhone || '',
        ccContactEmail: ccContactEmail || '',
        status: 'Pending',
        isReadOnly: false,
      });

      if (this.state.sitesurveyform) {
        this.state.files.length = 0;
        let file = new ChosenFile();
        file.parse(this.state.sitesurveyform);
        if (file.id) {
          this.state.files.push(file);
        }
      }
    }
    this.setState({ loading: false });
  }

  onChange = () => {
    this.isDirty();
    this.validate();
  }

  handleChange = (event, { name, value }) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  handleDateChange = (name, value) => {
    this.setState({ [name]: value }, () => { this.onChange(); });
  }

  handleCraneNameChange = (event, { index, value }) => {
    let newInfo = [ ...this.state.cranes ];
    newInfo[index] = {...newInfo[index], name: value};

    this.setState({ cranes: newInfo }, () => { this.onChange(); });
  }

  addCrane = (index) => {
    let newInfo = [ ...this.state.cranes ];
    newInfo.splice(index + 1, 0, {name: ""});

    this.setState({ cranes: newInfo }, () => {
      this.onChange();
      document.getElementById(`crane_name_${ index + 1 }`).scrollIntoView();
    });
  };

  removeCrane = (index) => {
    let newInfo = [ ...this.state.cranes ];
    newInfo.splice(index, 1);

    this.setState({ cranes: newInfo }, () => { this.onChange(); });
  };

  handleFileChooserChange = (state) => {
    let newState = { ...state };

    if (newState.files) {
      newState.sitesurveyform = newState.files.length === 1 ? newState.files[0].stringify() : '';
    }

    this.setState(newState, () => { this.onChange(); });
  }

  validate = () => {
    if (this.state.errors.error) {
      this.isValid()
    }
  }

  isValid = () => {
    let errors = { error: false };

    if (!this.state.status) {
      errors.error = true;
      errors.status = 'Please select a status.';
    }

    if (this.state.status !== 'Pending' && this.state.status !== 'Rejected' && !this.state.visitdate) {
      errors.error = true;
      errors.visitdate = 'Please choose a visit date.';
    }

    if (this.state.status === 'Completed' && !this.state.sitesurveyform) {
      errors.error = true;
      errors.files = 'Please upload a site survey form.';
    }

    if (this.state.status === 'Rejected' && !this.state.comments) {
      errors.error = true;
      errors.comments = 'Please enter a reason for the rejection in the Comments field.';
    }

    // TODO: more validation

    this.setState({ errors: errors });

    return !errors.error;
  }

  handleSubmit = event => {
    event.preventDefault();

    if (this.isValid()) {
      const { loading, isNew, files, errors, progressPercent, progressText, ...data } = this.state;

      if (this.state.isNew) {
        this.create(data);
      } else {
        this.update(data);
      }
    }
  }

  goToList = () => {
    this.isClean();
    this.props.history.push(`/${ PATH_FIRE_DEPARTMENT }/${ PATH_SITE_SURVEY }`);
  }

  updateFiles = async (data) => {
    const currentFile = this.props.survey.sitesurveyform ? [ this.props.survey.sitesurveyform ] : [];

    let toDelete = [];
    let toUpload = [];

    ChosenFile.syncFiles(currentFile, this.state.files, toDelete, toUpload);

    let success = true;

    if (toDelete.length === 1) {
      let deleteFile = toDelete[0];

      this.setState({
        progressPercent: 50,
        progressText: deleteFile.name
      });

      success = await deleteFile.delete();

      if (!success) {
        this.setState({
          errors: {
            error: true,
            files: `Failed to remove ${ deleteFile.name }`,
          },
          progressPercent: 0,
        });
      } else {
        this.setState({
          progressPercent: 100,
        });
      }
    }

    if (success && toUpload.length === 1) {
      let uploadFile = toUpload[0];
      success = await uploadFile.upload({
        onUploadProgress: (percent) => {
          this.setState({
            progressPercent: percent,
            progressText: uploadFile.name
          });
        }
      });
      if (!success) {
        this.setState({
          errors: {
            error: true,
            files: `Failed to upload ${ uploadFile.name }`,
          },
          progressPercent: 0,
        });
      } else {
        data.sitesurveyform = uploadFile.stringify();
        this.setState({
          sitesurveyform: data.sitesurveyform
        });
      }
    }

    return success;
  }

  create = async (data) => {
    //Make request
    this.setState({ loading: 'Syncing Files' });

    const success = await this.updateFiles(data);

    if (success) {
      this.setState({ loading: 'Submitting' });
      const postData = data;
      delete postData.ackOpen;
      delete postData.ccAcknowledged;
      delete postData.isPrinting;
      delete postData.isReadOnly;
      await postFireDepartmentSurvey(data);
    if (this.props.survey.id) {
        this.goToList();
      }
    }

    this.setState({ loading: false });
  }

  update = async (data) => {
    this.setState({ loading: 'Syncing Files' });

    const success = await this.updateFiles(data);

    if (success) {
      this.setState({ loading: 'Updating' });
      await updateFireDepartmentSurvey(data);
      if (this.props.survey.id) {
        this.goToList();
      }
    }

    this.setState({ loading: false });
  }

  handleAck() {
    console.log('de', this.ackModal)
    if (this.ackModal) this.ackModal.show();
  }

  print = event => {
    this.setState({ isPrinting: true });
    const surveyPDF = new SurveyPDF();
    const cranes = this.state.cranes.map(c => c.name).join(' - ');
    surveyPDF.createPDF({ ...this.state, cranes: cranes } );
    this.setState({ isPrinting: false });

    event.preventDefault();
  }

  cancel = event => {
    event.preventDefault();

    // Back to the list
    this.goToList();
  }

  render() {
    const statuses = SURVEY_STATUS_LIST.map((status) => { return { key: status, value: status, text: status }});
    const types = Object.keys(SURVEY_TYPES).map((key) => { return { key: key, value: key, text: SURVEY_TYPES[key] }});
    const surveyCategories = Object.keys(SURVEY_CATEGORIES).map((key) => { return { key: key, value: key, text: SURVEY_CATEGORIES[key] }});

    return (
      <div className='construction-survey'>
        <Grid centered className='view-grid'>
          <Grid.Column className='view-column'>
            {(() => {
              if (this.state.loading) {
                return <ViewLoader loading={ this.state.loading } percent={ this.state.progressPercent } text={ this.state.progressText } />;
              }

              return (
                <Form onSubmit={ this.handleSubmit } error={ this.state.errors.error }>
                  <Dimmer.Dimmable>
                    <Dimmer active={ this.state.isPrinting } page verticalAlign='top'>
                      <Loader size='huge'>Printing</Loader>
                    </Dimmer>
                  </Dimmer.Dimmable>
                  <Header size='medium'>
                    <Header.Content>Site Survey Request</Header.Content>
                    { !this.state.isReadOnly &&
                      <Form.Button floated='right' className='form-top-button' positive>{ this.state.isNew ? 'Submit Request' : 'Update Request' }</Form.Button>
                    }
                    { this.state.ccAcknowledged &&
                      <Form.Button floated='right' className='form-top-button' color='blue' type='button' onClick={ this.handleAck.bind(this) }>Acknowledgement</Form.Button>
                    }
                    <Form.Button floated='right' className='form-top-button' color='blue' type='button' onClick={ this.print }>Print</Form.Button>
                    <Form.Button floated='right' className='form-top-button' negative type='button' onClick={ this.cancel }>Cancel</Form.Button>
                    <Header.Subheader>
                      Review the Standard Operating Procedure(s) related to this page
                      <ExternalButton style={{ marginLeft: '0.5rem' }} size='tiny' compact basic pathname={ EXTERNAL_PATH_ADM2 } label="ADM2" />
                    </Header.Subheader>
                  </Header>
                  <ConstructionCraneSurveyAckModal ref={(ref)=>{this.ackModal = ref}} isViewOnly/>
                  <Segment.Group id="printable" size='large' className='view-segment-group'>
                    { this.state.isPrinting &&
                      <Segment style={{ background: '#ebebeb' }}>
                        <Image src={ logo } size='medium' className='brand'/>
                      </Segment>
                    }
                    <Segment disabled={ this.state.isReadOnly && !this.state.isPrinting }>
                      <Form.Group>
                        <Form.Field required={ this.state.status !== 'Pending' && this.state.status !== 'Rejected' } readOnly={ this.state.isReadOnly && !this.state.isPrinting }
                          label='Site Visit Date' name='visitdate' width={ 5 }
                          control={ DateControl } date={ this.state.visitdate } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                          onChange={ this.handleDateChange }
                        />
                        { !this.state.isReadOnly &&
                          <Form.Select required
                            label='Category' name='surveyCategory' value={ this.state.surveyCategory } options={ surveyCategories } width={ 4 }
                            onChange={ this.handleChange }
                          />
                        }
                        { this.state.isReadOnly &&
                          <Form.Input readOnly={ !this.state.isPrinting }
                            label='Status' name='status' value={ this.state.status || '' } width={ 6 }
                          />
                        }
                        { !this.state.isReadOnly &&
                          <Form.Select required disabled={ this.state.isNew }
                            label='Status' name='status' value={ this.state.status } options={ statuses } width={ 6 }
                            onChange={ this.handleChange }
                          />
                        }
                        <Form.Field required={ this.state.status === 'Completed' || this.state.isNew } readOnly={ this.state.isReadOnly }
                          label='Site Survey Form' name='files' width={ 5 }
                          control={ FileChooserSegment } files={ this.state.files } withInput
                          onChange={ this.handleFileChooserChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.visitdate }{ this.state.errors.status }{ this.state.errors.files }
                      </Message>
                      <Form.Group>
                        <Form.Input readOnly={ this.state.isReadOnly && !this.state.isPrinting }
                          label='Comments' name='comments' value={ this.state.comments } width={ 16 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      <Message error>
                        { this.state.errors.comments }
                      </Message>
                    </Segment>
                    <Segment disabled={ !this.state.isPrinting } hidden={ this.state.isNew }>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isPrinting }
                          label='Construction Company' name='ccName' value={ this.state.ccName } width={ 6 }
                        />
                        <Form.Input readOnly={ !this.state.isPrinting }
                          label='Contact Name' name='ccContactName' value={ this.state.ccContactName } width={ 6 }
                        />
                        <Form.Input readOnly={ !this.state.isPrinting }
                          label='Contact Phone' name='ccContactPhone' value={ this.state.ccContactPhone } width={ 4 }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isPrinting }
                          label='Contact Email' name='ccContactEmail' value={ this.state.ccContactEmail } width={ 16 }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isPrinting }
                          label='Submitted By' name='submittedby' value={ this.state.submittedby || this.state.ccContactName } width={ 6 }
                        />
                        <Form.Field readOnly={ !this.state.isPrinting }
                          label='Submission Date' name='timestamp' width={ 3 }
                          control={ DateControl } date={ this.state.timestamp } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                        />
                      </Form.Group>
                    </Segment>
                    <Segment disabled={ !this.state.isNew && !this.state.isPrinting }>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                          label='Project Name' value={ this.state.project_name } name='project_name' width={ 8 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                          label='Address Number' value={ this.state.project_street_number } name='project_street_number' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                          label='Street' value={ this.state.project_street_name } name='project_street_name' width={ 8 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                          label='City' value={ this.state.project_city } name='project_city' width={ 8 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Province' value={ this.state.project_province } name='project_province' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                          label='Postal Code' value={ this.state.project_postal_code } name='project_postal_code' width={ 4 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                    </Segment>
                    <Segment disabled={ !this.state.isNew && !this.state.isPrinting }>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                          label='Primary Liason' value={ this.state.project_l1_name } name='project_l1_name' width={ 8 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                          label='Main Phone' value={ this.state.project_l1_main_telephone_number } name='project_l1_main_telephone_number' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Ext' value={ this.state.project_l1_main_telephone_ext } name='project_l1_main_telephone_ext' width={ 2 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Secondary Phone' value={ this.state.project_l1_secondary_telephone_number } name='project_l1_secondary_telephone_number' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Ext' value={ this.state.project_l1_secondary_telephone_ext } name='project_l1_secondary_telephone_ext' width={ 2 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                    </Segment>
                    <Segment disabled={ !this.state.isNew && !this.state.isPrinting }>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Secondary Liason' value={ this.state.project_l2_name } name='project_l2_name' width={ 8 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Main Phone' value={ this.state.project_l2_main_telephone_number } name='project_l2_main_telephone_number' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Ext' value={ this.state.project_l2_main_telephone_ext } name='project_l2_main_telephone_ext' width={ 2 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Secondary Phone' value={ this.state.project_l2_secondary_telephone_number } name='project_l2_secondary_telephone_number' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Ext' value={ this.state.project_l2_secondary_telephone_ext } name='project_l2_secondary_telephone_ext' width={ 2 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                    </Segment>
                    <Segment disabled={ !this.state.isNew && !this.state.isPrinting }>
                      <Form.Group>
                        <Form.Input required readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Emergency Contact' value={ this.state.afterHoursContactName } name='afterHoursContactName' width={ 4 }
                          onChange={ this.handleChange }
                        />
                        <Form.Input required readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Emergency Contact Phone' value={ this.state.afterHoursPhone } name='afterHoursPhone' width={ 4 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                    </Segment>
                    <Segment disabled={ !this.state.isNew && !this.state.isPrinting }>
                      <Form.Group>
                        { this.state.isReadOnly &&
                          <Form.Input readOnly={ !this.state.isPrinting }
                            label='Request Type' name='request_type' value={ SURVEY_TYPES[this.state.request_type] || '' } width={ 4 }
                          />
                        }
                        { !this.state.isReadOnly &&
                          <Form.Select required
                            label='Request Type' name='request_type' value={ this.state.request_type } options={ types } width={ 4 }
                            onChange={ this.handleChange }
                          />
                        }
                        { this.state.status && this.state.status !== 'Pending' &&
                          <Form.Field readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                            label='Initial Date' name='availablefrom' width={ 3 }
                            control={ DateControl } date={ this.state.availablefrom } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                            onChange={ this.handleDateChange }
                          />
                        }
                        { this.state.status && this.state.status !== 'Pending' &&
                          <Form.Field readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                            label='Final Date' name='availableto' width={ 3 }
                            control={ DateControl } date={ this.state.availableto } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                            onChange={ this.handleDateChange }
                          />
                        }
                        { this.state.status && this.state.status === 'Pending' &&
                          <Form.Field readOnly={ !this.state.isNew && !this.state.isPrinting } required={ this.state.isNew }
                            label='Anticipated Service End Date' name='availableto' width={ 4 }
                            control={ DateControl } date={ this.state.availableto } dateFormat={ DATE_FULL_MONTH_DAY_YEAR }
                            onChange={ this.handleDateChange }
                          />
                        }
                      </Form.Group>
                      <Form.Group>
                        <Form.Input readOnly={ !this.state.isNew && !this.state.isPrinting }
                          label='Comments' value={ this.state.request_comments } name='request_comments' width={ 10 }
                          onChange={ this.handleChange }
                        />
                      </Form.Group>
                    </Segment>
                    { this.state.cranes.map((crane, index, list) => (
                      <CraneSegment index={index} key={index} dataSource={this} disabled={ this.state.isReadOnly }/>
                    ))}
                    { this.state.isPrinting &&
                      <Segment>
                        <Form.Group>
                          <div>Signature:</div>
                        </Form.Group>
                      </Segment>
                    }
                    { !this.state.isPrinting && this.state.signature &&
                      <Segment>
                        <Header size='small'>
                          <Header.Content>Digital signature:</Header.Content>
                        </Header>
                        <Grid>
                          <Grid.Column> <AsyncImage fileName={this.state.signature} size={"medium"}/> </Grid.Column>
                        </Grid>
                      </Segment>
                    }
                  </Segment.Group>
                </Form>
              );
            })()}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

function mapStoreStateToProps(storeState) {
  return {
    currentUser: storeState.auth.currentUser,
    survey: storeState.models.fireDepartmentSurvey,
    cc: storeState.models.user,
    settings: storeState.lookups.settings,
  };
}

export default connect(mapStoreStateToProps)(FireDepartmentSiteSurvey);
