import pdfMake from 'pdfmake';
import pdfFonts from "pdfmake/build/vfs_fonts";
import bccsa_header from './resources/bccsa_header';
import bccsa_footer from './resources/bccsa_footer';
import { formatDateTime } from '../utils/date';
import { DATE_FULL_MONTH_DAY_YEAR } from '../js/constants';
import { SURVEY_TYPES, SURVEY_CATEGORIES } from '../js/lists';

class SurveyPDF {
  constructor() {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
  }

  createPDF(data) {
    pdfMake.createPdf(this._docDefinition(data)).download();
  }

  _docDefinition(data) {
    return {
      content: [
        { image: bccsa_header.header, width: 612, margin: [-20, -10, -20, 0] },
        {
          margin: [10, 5, 10, 0],
          layout: {
            hLineColor: function (i, node) { return (i !== 0 && i !== 2 ? 'black' : 'red'); },
            vLineColor: function (i, node) { return 'red'; },
          },
          table: {
            headerRows: 1,
            widths: ['*'],
            body: [
              [
                { text: 'TECHNICAL HIGH ANGLE ROPE RESCUE PROGRAM\nFIRE SERVICE APPLICATION FOR TECHNICAL HIGH ANGLE ROPE RESCUE SERVICE ', bold: true, fontSize: 8, alignment: 'center' }
              ],
              [
                {
                  text: [
                    'We, the undersigned, being authorized representatives of the business named herein (hereinafter referred to as the “Company”), and contributors to the funding of the Fire Service Rope Rescue Program (hereinafter referred to as the “Service”), hereby request said Service for the duration of the Company’s construction project indicated below. The Company acknowledges and understands that eligibility for, and access to the Service shall remain contingent upon the Company’s continuing compliance with the prerequisite Terms and Conditions of Service defined in this document. The Company further acknowledges that from time to time, authorized representatives of the Service reserve the right to verify the Company is in compliance with the Terms and Conditions of Service and agree to cooperate with the representatives during such verifications. The Company fully understands that failure to comply with the Terms and Conditions of Service may result in termination of Service, which may result in the Company being in contravention of Workers’ Compensation Board’s Occupational Health and Safety Regulation. ',
                    { text: '“Tower Crane Technical High Angle Rope Rescue Service or Service is understood to mean: Command and execution of a rope rescue by a Fire Department and does not imply any guarantee of the success of a rescue.”', bold: true }
                  ],
                  fontSize: 8, alignment: 'left'
                }
              ],
              [
                { text: 'For full Terms and Conditions, the Company and the Fire Department may refer to the full Responsibility to Provide Rope Rescue Service standard operating procedure (ADM 2).', background: 'yellow', fontSize: 12, alignment: 'center', border: [false, false, false, false] }
              ]
            ]
          }
        },
        {
          margin: [10, 5, 10, 0],
          layout: {
            hLineColor: function (i, node) { return 'white'; },
            vLineColor: function (i, node) { return 'white'; },
          },
          table: {
            body: [
              [{
                columns: [
                  { text: 'Construction Company:', alignment: 'left', width: 90 },
                  { text: data.ccName, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Contact Name:', alignment: 'left', width: 90  },
                  { text: data.ccContactName, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Contact Phone:', alignment: 'left', width: 90  },
                  { text: data.ccContactPhone, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Contact Email:', alignment: 'left', width: 90  },
                  { text: data.ccContactEmail, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Submitted By:', alignment: 'left', width: 90  },
                  { text: data.submittedby || data.ccContactName, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Submission Date:', alignment: 'left', width: 90  },
                  { text: formatDateTime(data.timestamp, DATE_FULL_MONTH_DAY_YEAR), alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Site Visit Date:', alignment: 'left', width: 90  },
                  { text: formatDateTime(data.visitdate, DATE_FULL_MONTH_DAY_YEAR), alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Status:', alignment: 'left', width: 90  },
                  { text: data.status, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Comments:', alignment: 'left', width: 90  },
                  { text: data.comments, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Project Name:', alignment: 'left', width: 90  },
                  { text: data.project_name, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Address Number:', alignment: 'left', width: 90  },
                  { text: data.project_street_number, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Street:', alignment: 'left', width: 90  },
                  { text: data.project_street_name, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'City:', alignment: 'left', width: 90  },
                  { text: data.project_city, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Province:', alignment: 'left', width: 90  },
                  { text: data.project_province, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Postal Code:', alignment: 'left', width: 90  },
                  { text: data.project_postal_code, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: '', width: 90  },
                  { text: '', width: 150 },
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Primary Liason:', alignment: 'left', width: 90  },
                  { text: data.project_l1_name, alignment: 'left', width: 400 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Main Phone:', alignment: 'left', width: 90  },
                  { text: data.project_l1_main_telephone_number, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Ext:', alignment: 'left', width: 90  },
                  { text: data.project_l1_main_telephone_ext, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Secondary Phone:', alignment: 'left', width: 90  },
                  { text: data.project_l1_secondary_telephone_number, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Ext:', alignment: 'left', width: 90  },
                  { text: data.project_l1_secondary_telephone_ext, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Secondary Liason:', alignment: 'left', width: 90  },
                  { text: data.project_l2_name, alignment: 'left', width: 400 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Main Phone:', alignment: 'left', width: 90  },
                  { text: data.project_l2_main_telephone_number, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Ext:', alignment: 'left', width: 90  },
                  { text: data.project_l2_main_telephone_ext, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Secondary Phone:', alignment: 'left', width: 90  },
                  { text: data.project_l2_secondary_telephone_number, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Ext:', alignment: 'left', width: 90  },
                  { text: data.project_l2_secondary_telephone_ext, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Emergency Contact:', alignment: 'left', width: 90  },
                  { text: data.afterHoursContactName, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Emergency Contact Phone:', alignment: 'left', width: 90  },
                  { text: data.afterHoursContactPhone, alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Request Type:', alignment: 'left', width: 90  },
                  { text: SURVEY_TYPES[data.request_type], alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Category:', alignment: 'left', width: 90  },
                  { text: SURVEY_CATEGORIES[data.surveyCategory], alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Available from:', alignment: 'left', width: 90  },
                  { text: formatDateTime(data.availablefrom, DATE_FULL_MONTH_DAY_YEAR), alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Available to:', alignment: 'left', width: 90  },
                  { text: formatDateTime(data.availableto, DATE_FULL_MONTH_DAY_YEAR), alignment: 'left', width: 150 }
                ],
                columnGap: 10
              }],
              [{
                columns: [
                  { text: 'Request Comments:', alignment: 'left', width: 90 },
                  { text: data.request_comments, alignment: 'left', width: 150 },
                  { text: '' },
                  { text: 'Cranes:', alignment: 'left', width: 90  },
                  { text: data.cranes, alignment: 'left', width: 150 }
                ],
                columnGap: 10,
              }],
            ]
          }
        },
        {
          margin: [10, 5, 10, 0],
          layout: {
            hLineColor: function (i, node) { return 'red'; },
            vLineColor: function (i, node) { return 'red'; },
          },
          table: {
            body: [
              [
                {
                  text: [
                    'We, the undersigned, being authorized representatives of the Company and the Fire Service, agree to the Terms and Conditions of Service and additional requirements stated herein. ',
                    { text: 'A copy of this application and the Terms of Conditions have been received by the Company. ', bold: true },
                    'The Company shall be eligible for Service as of this date.'
                  ],
                  fontSize: 8, alignment: 'left', border: [true, true, true, false]
                }
              ],
              [ { text: '', border: [true, false, true, false] } ],
              [{
                columns: [
                  { text: 'FOR THE COMPANY:', alignment: 'right', width: 100 },
                  {
                    text: [
                      { text: '___________________________________________________\n' },
                      { text: 'Representative Name and Title (please print)' },
                    ],
                    fontSize: 6, alignment: 'center', margin: [0, 5]
                  },
                                {
                    text: [
                      { text: '___________________________________________________\n' },
                      { text: 'Signature' },
                    ],
                    fontSize: 6, alignment: 'center', margin: [0, 5]
                  },
                ],
                columnGap: 20,
                border: [true, false, true, false]
              }],
              [ { text: '', border: [true, false, true, false] } ],
              [{
                columns: [
                  { text: 'FOR THE SERVICE:', alignment: 'right', width: 100 },
                  {
                    text: [
                      { text: '___________________________________________________\n' },
                      { text: 'Representative Name and Title (please print)' },
                    ],
                    fontSize: 6, alignment: 'center', margin: [0, 5]
                  },
                                {
                    text: [
                      { text: '___________________________________________________\n' },
                      { text: 'Signature' },
                    ],
                    fontSize: 6, alignment: 'center', margin: [0, 5]
                  },
                ],
                columnGap: 20,
                border: [true, false, true, false]
              }],
              [ { text: '', border: [true, false, true, false] } ],
              [
                { text: 'DATED this ______ day of ______________ 20_____', alignment: 'center', border: [true, false, true, true] }
              ],
            ]
          }
        },
        { image: bccsa_footer.footer, width: 612, margin: [-20, 0, -20, 0] }
      ],
      pageSize: 'LETTER',
      pageMargins: [20, 10, 20, 0],
      defaultStyle: { fontSize: 8 },
      pageOrientation: 'portrait',
    }
  }
}

export default SurveyPDF;
